import React, { useEffect, useState } from "react";
import AccountDay from "../BuyerStats/AccountDay";
import mainAPI from "../../../utils/mainAPI";
import { DragDropContext } from "react-beautiful-dnd";
import { MenuItem, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownload from "js-file-download";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
const AccountsStats = () => {
  const [fileName, setFileName] = useState("");
  const [resetDialog, setResetDialog] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedResetDay, setSelectedResetDay] = useState(1);
  const [selectedType, setSelectedType] = useState(1);
  useEffect(() => {
    getAllAccounts();
  }, []);
  function getAllAccounts() {
    mainAPI.get("/farm/allaccounts").then((res) => {
      setAllAccounts(res.data);
    });
  }
  function changeAccountStatus(id, status, day) {
    mainAPI
      .post("/farm/changeaccountstatus", {
        id: id,
        status: status,
        day: day,
      })
      .then(() => {
        getAllAccounts();
      })
      .catch((e) => {
        toast.error("Error");
      });
  }
  async function handleOnDragEnd(result) {
    if (result.source.droppableId !== result.destination.droppableId) {
      const data = result.destination.droppableId.split("_");
      changeAccountStatus(result.draggableId, data[1], data[2]);
    }
  }
  async function postResetAccounts() {
    mainAPI.post("/farm/reset", { day: selectedResetDay }).then((res) => {
      toast.success("Успешно");
      setResetDialog(false);

      getAllAccounts();
    });
  }
  function handleFilterChange(e) {
    const filtered = [];
    allAccounts.forEach((item) => {
      if (item.name.toString().indexOf(e.target.value) !== -1) {
        filtered.push(item);
      }
    });
    setFilteredAccounts(filtered);
    setFilterValue(e.target.value);
  }
  function exportCsv() {
    mainAPI
      .get("/farm/csv?day=" + selectedDay + "&type=" + selectedType)
      .then((res) => {
        FileDownload(res.data, `accounts.csv`);
      });
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              {" "}
              <TextField
                onChange={(e) => handleFilterChange(e)}
                value={filterValue}
                label="Поиск"
                size="small"
                placeholder="253"
              />
              <Button
                style={{ marginLeft: "30px" }}
                onClick={() => setResetDialog(true)}
              >
                Сбросить в активное
              </Button>
            </div>
            <FileDownloadIcon
              onClick={() => setOpenDownloadModal(true)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="account_days">
            <AccountDay
              day="1"
              data={filterValue.length ? filteredAccounts : allAccounts}
              refresh={getAllAccounts}
            />
            <AccountDay
              day="2"
              data={filterValue.length ? filteredAccounts : allAccounts}
              refresh={getAllAccounts}
            />
            <AccountDay
              day="3"
              data={filterValue.length ? filteredAccounts : allAccounts}
              refresh={getAllAccounts}
            />
            <AccountDay
              day="4"
              data={filterValue.length ? filteredAccounts : allAccounts}
              refresh={getAllAccounts}
            />
            <AccountDay
              day="5"
              data={filterValue.length ? filteredAccounts : allAccounts}
              refresh={getAllAccounts}
            />
          </div>
        </div>
      </DragDropContext>
      <Dialog
        open={resetDialog}
        onClose={() => setResetDialog(false)}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">Перенос в зеленое</DialogTitle>
        <DialogContent>
          <Select
            size="small"
            value={selectedResetDay}
            onChange={(e) => setSelectedResetDay(e.target.value)}
          >
            <MenuItem value={1}>Понедельник</MenuItem>
            <MenuItem value={2}>Вторник</MenuItem>
            <MenuItem value={3}>Среда</MenuItem>
            <MenuItem value={4}>Четверг</MenuItem>
            <MenuItem value={5}>Пятница</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => postResetAccounts()} variant="outlined">
            Перенести
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        aria-labelledby="watchtoken-title"
        aria-describedby="watchtoken-description"
      >
        <DialogTitle id="watchtoken-slide-title">Export CSV</DialogTitle>
        <DialogContent>
          <Select
            size="small"
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
          >
            <MenuItem value={1}>Понедельник</MenuItem>
            <MenuItem value={2}>Вторник</MenuItem>
            <MenuItem value={3}>Среда</MenuItem>
            <MenuItem value={4}>Четверг</MenuItem>
            <MenuItem value={5}>Пятница</MenuItem>
          </Select>
          <br />
          <Select
            style={{ marginTop: "10px" }}
            size="small"
            fullWidth
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <MenuItem value={1}>TW ES</MenuItem>
            <MenuItem value={2}>TW KE</MenuItem>
            <MenuItem value={3}>PP KE</MenuItem>
            <MenuItem value={4}>TW GE</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => exportCsv()} variant="outlined">
            Скачать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountsStats;
