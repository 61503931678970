import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { Container, Typography } from '@mui/material';

import Streams from './Streams';

function Cloaker() {
  useEffect(() => {
    document.title = '2leads App - Клоакер';
  }, []);

  return (
    <>
      <Typography variant='h4' style={{ textAlign: 'left', marginLeft: 50 }}>
        Клоакер
      </Typography>

      <Container>
        <Streams />
      </Container>
    </>
  );
}

export default observer(Cloaker);
